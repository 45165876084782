@mixin xs {
  @media screen and (max-width: 767px) {
    @content; } }

@mixin sm {
  @media screen and (min-width: 768px) {
    @content; } }

@mixin md {
  @media screen and (min-width: 992px) {
    @content; } }

@mixin lg {
  @media screen and (min-width: 1200px) {
    @content; } }

@mixin xl {
  @media screen and (min-width: 1500px) {
    @content; } }

@mixin media($size) {
  @media screen and (min-width: $size) {
    @content; } }
