.forbidden-notification {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  // transform: translate(-50%, -50%)
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0,.3);
  border-radius: 0;

  .ant-notification-notice-with-icon {
    text-align: center;
    position: relative;
    padding: 30px;
    background-color: #fff;
    border-radius: 4px;

    .ant-notification-notice-btn {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 0;
      padding: 6px 10px; }

    .ant-notification-notice-icon {
      color: red;
      display: block;
      position: relative;
      margin: 0 auto 15px;
      font-size: 42px; }

    .ant-notification-notice-message {
      padding-right: 0;
      margin: 0;
      width: 100%; } }

  .ant-notification-notice-close {
    display: none; } }
