@import '../assets/sass/colors';
@import '../assets/sass/media';
@import '../assets/sass/components/notifications/forbidden';
@import '../assets/sass/components/notifications/custom-error';

body {
  background: $bg; }

.base-layout {
  padding-top: 64px;
  min-height: calc(100vh - 64px); }

.hidden-xs {
  display: none !important;
  @include sm {
    display: block !important; } }

.show-xs {
  display: block !important;
  @include sm {
    display: none !important; } }
