@import '../../assets/sass/colors';

.logo {
  padding: 14px;
  background: $white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 32px; }

  h3 {
    font-size: 1rem;
    margin-left: 20px;
    margin-bottom: 0;
    line-height: 1; } }
