@import '../../assets/sass/colors';

.quantity-input-group {
  display: flex;
  justify-content: center;
  border: 1px solid darken($hr, 15%);
  border-radius: 4px;
  overflow: hidden;

  .ant-input {
    &[type="button"] {
      padding: 0 12px;
      border-color: transparent;
      box-shadow: none;
      border-radius: 0;
      line-height: 1;
      cursor: pointer;

      &:hover:not([disabled]) {
        color: $primary; }

      &[disabled] {
        cursor: default;
        &:hover {
          border-color: transparent; } } }

    &[type="text"] {
      max-width: 42px;
      text-align: center;
      padding: 4px 2px;
      border-color: transparent;
      border-radius: 0;

      &:focus {
        box-shadow: none; } } } }
