@import '../../assets/sass/media';

.ant-modal-content {
  border-radius: 0; }

.full-screen-modal {
  overflow: initial;
  display: flex;

  @include sm {
    margin: 8px; }

  .ant-modal {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 0;
    display: flex;
    overflow: hidden;

    @include sm {
      min-height: 100%; } }

  .ant-modal-content {
    width: 100%;
    flex: 1 0 auto; }

  .ant-modal-body {
    overflow: auto;
    max-height: calc(100vh - 255px);

    @include sm {
      max-height: calc(100vh - 130px); }

    @include lg {
      max-height: calc(100vh - 95px); } }

  &.admin-modal {
    .ant-modal-body {
      max-height: calc(100vh - 220px);

      @include sm {
        max-height: calc(100vh - 130px); }

      @include md {
        max-height: calc(100vh - 95px); } } } }
