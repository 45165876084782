@import '../../assets/sass/media';

.side-bar {
  background: transparent;

  @include xs {
    position: fixed;
    height: 100%;
    z-index: 99; }

  .ant-menu-root {
    position: sticky;
    top: 64px;
    height: calc(100vh - 64px);
    overflow-y: auto;

    .ant-menu-inline {
      background-color: #FAFAFA; } } }


@media print {
  .ant-menu {
    display: none;
    width: 0px; } }
