@import '../../assets/sass/path';
@import '../../assets/sass/colors';
@import '../../assets/sass/media';

$error: #f98178;

.login {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: flex-start;
  min-height: 100vh;
  width: 100%;
  background-color: $bg;
  overflow: auto;

  .login-top-bar {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center; }

  .login-section {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 15px 30px; }

  .login-title {
    font-size: 24px;
    letter-spacing: .015em;
    line-height: 1.2;
    margin-bottom: 0;
    text-align: center;
    color: $dark;

    @include sm {
      font-size: 28px; }

    @include media(1440px) {
      font-size: 30px; } }

  .login-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    box-shadow: 0 0 20px -5px rgba($black, 0.1);
    overflow: hidden;

    @include sm {
      flex-flow: row nowrap;
      align-items: stretch;
      max-width: 700px; }

    @include media(1440px) {
      max-width: 800px; }

    .login-logo {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      margin-bottom: 15px;
      width: 100%; }

    .login-logo-image {
      margin-bottom: 10px;
      width: 50px;

      @include md {
        width: 60px; }

      img {
        max-width: 100%;
        height: auto;
        display: block; } }

    .login-logo-title {
      text-align: center;
      font-size: 1.25rem;
      line-height: 1.2;
      width: 100%;
      margin-bottom: 0;

      @include md {
        font-size: 1.5rem; } }

    .login-welcome {
      text-align: center;
      margin-bottom: 30px;
      width: 100%;

      p {
        font-size: 12px;
        line-height: 1.35;
        margin-bottom: 0; } }

    .login-btn {
      background-color: $dark;
      color: $light;
      border: none;
      padding-left: 30px;
      padding-right: 30px;

      &:hover {
        background-color: lighten($dark, 20%); } }

    .login-error {
      text-align: center;
      color: $error;
      padding: 10px 0 0;
      margin-bottom: 0;
      line-height: 1.35; } }

  .login-box {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    padding: 40px 30px;
    background-color: $light;

    @include sm {
      width: 40%;
      padding: 15px; } }

  .login-aside {
    display: none;

    @include sm {
      display: flex;
      width: 60%; }

    &-image {
      display: flex;
      width: 100%;
      margin: 0;

      img {
        display: flex;
        width: 100%;
        max-width: 100%;
        height: auto; } } }

  .login-bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px; }

  .sd-group {
    max-width: 500px;

    @include media(1440px) {
      max-width: 700px; }

    img {
      display: block;
      height: auto;
      max-width: 100%; } }

  .label-list {
    display: flex;
    list-style: none;
    padding: 10px;
    margin-bottom: 0;
    border-radius: 6px;

    @include sm {
      max-width: 500px;
      width: 100%;
      justify-content: space-between; }

    .label-item {
      align-self: center;

      @include sm {
        margin-left: 10px;
        margin-right: 10px; }

      img {
        min-width: 460px;
        max-width: 100%;
        height: auto; } } } }
