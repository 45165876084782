@import '../../assets/sass/colors';

.value-line {
  display: inline-table;
  &:after {
    display: table-cell;
    width: 50%;
    height: 20px;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
    content: ''; }

  .value-content {
    padding-right: 10px; } }

.time {
  padding-left: 10px; }

.points-model {
  width: 800px !important;
  text-align: center;

  .ant-modal-content {
    text-align: -webkit-center; }

  .ant-modal-body {
    width: 60%; } }

.description {
  font-size: small;
  font-weight: 200;
  text-align: left;
  margin-left: 10px; }

.tagTypes {
  line-height: 15px;
  font-weight: normal !important; }

.canceledText {
  text-decoration: line-through; }

