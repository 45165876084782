@import '../../assets/sass/media';

.page-header {
  padding: 16px 0;

  h1 {
    font-size: 22px; }

  @include sm {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center; }

  .page-header-left:only-child {
    h1 {
      text-align: center; }

    @include sm {
      width: 100%; } }

  .page-header-left,
  .page-header-right {
    width: 100%;

    @include sm {
      width: 50%; } }

  .page-header-right {
    display: flex;
    justify-content: flex-end;

    @include sm {
      padding-left: 12px; } } }
