$white: white;
$black: #000;
$dark: #222;
$light: #fff;
$primary: #1890ff;
$bg: #F7F8F9;
$hr: #E8E8E8;

$green-darken: #3c763d;
$green: #edf5ea;

$success: #4AC964;
$warning: #fa8c16;
