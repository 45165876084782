.amcharts-AmChartsLogo-group {
  display: none; }

.gridStyle {
  width: 100%;

  @media screen and (min-width: 768px) {
    width: calc(100% / 3); } }

.chart-content {
  width: 100%;
  height: 300px;

  @media screen and (min-width: 768px) {
    height: 500px; } }

.chart-content-gauge {
  width: 100%;
  height: 200px; }

.legend-wrapper {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (min-width: 768px) {
    width: 50%;
    margin: auto; } }
